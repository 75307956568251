<template>
	<div class="pdpage">
		<div style="height: 40px;">

			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>首页</el-breadcrumb-item>
				<el-breadcrumb-item>店铺设置</el-breadcrumb-item>

			</el-breadcrumb>
		</div>

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>基本设置</span>

			</div>
			<div>
				<el-form ref="form" v-if="shopInfo" :model="shopInfo">

					<el-form-item label="店铺名称">
						<el-col :span="24">
							<el-input type='text' v-model="shopInfo.shop_name" placeholder="例如:郁金香小区8号楼2单元5楼南户">
							</el-input>
							<div style="color: blue; font-size: 12px; line-height: 20px;">
								设置名称可以方便别人上门取件，可设置为小区名称+楼号+单元+房间号，如【郁金香小区8号楼2单元5楼南户】
							</div>
						</el-col>

					</el-form-item>

					<el-form-item label="送货上门" v-if="false">
						<el-col :span="24">
							<el-radio-group v-model="shopInfo.can_shsm">
								<el-radio :label="0">不支持</el-radio>
								<el-radio :label="1">支持</el-radio>
							</el-radio-group>

							<span style="margin-left: 20px;" v-if="shopInfo.can_shsm">送货上门加钱：</span>
							<el-input v-if="shopInfo.can_shsm" type='number' v-model="shopInfo.shsm_price"
								style="width: 100px;"> </el-input>
							<span v-if="shopInfo.can_shsm">元</span>
							<div style="color: blue; font-size: 12px; line-height: 20px; width: 100%;">
								只支持2公里内送货上门，超过两公里可不送货
							</div>
						</el-col>


					</el-form-item>
					<el-form-item label="详细地址">
						<el-col :span="24">
							<el-input type='text' v-model="shopInfo.address" placeholder="请输入xx市xx小区"> </el-input>
						</el-col>

						<!-- <el-col :span="24" style="text-align: right;padding-top: 10px;">
							<el-button class="el-icon-location" @click="getGps">点击获取定位</el-button>
						</el-col> -->
						<!-- <el-col :span="24">
							<div style="color: blue; font-size: 12px; line-height: 20px;">
								设置定位可以通过查找附近打印机找到你
							</div>
						</el-col> -->


					</el-form-item>

					<el-form-item label="联系电话">
						<el-col :span="24">
							<el-input type='text' v-model="shopInfo.phone" placeholder="手机号">
							</el-input>

						</el-col>

					</el-form-item>

				</el-form>
			</div>
		</el-card>

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>价格设置（只需设置打印机支持类型价格即可）</span>

			</div>
			<div>
				<el-form ref="form" v-if="shopInfo" :model="shopInfo" label-width="120px">
					<el-row v-for="(price,idx) in priceList" :key="idx">

						<el-col :span="16">
							<el-form-item :label="price.paper_type+(price.color?'彩色':'黑白')">
								<el-input type='text' v-model="price.price" placeholder="请输入价格"> </el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<span style="padding-left: 10px;line-height: 40px;">元/张</span>
						</el-col>



					</el-row>

				</el-form>
			</div>
		</el-card>

		<div style="text-align: center;padding:20px 0 50px 0;">
			<el-button type="primary" style="width: 80%;" @click="saveForm">保存设置</el-button>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	export default {
		data() {
			return {
				shopInfo: null,
				priceList: []
			}
		},
		mounted() {
			document.title="店铺设置"
			this.waitLogin().then(_=>{
				this.getData()
				//this.checkUrlSign(wx)
			})
			
			
			

		},
		methods: {
			getData() {
				this.$http.post("/api/get_shop_info").then(res => {
					this.shopInfo = res.data

					this.getPrice()
				})
			},
			getPrice() {
				this.$http.post("/api/get_shop_prices").then(res => {
					this.priceList = res.data

				})
			},
			getGps() {
				$("#loading").show();
				wx.getLocation({
					type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
					isHighAccuracy: true,
					highAccuracyExpireTime: 10000,
					success: (res) => {
						this.shopInfo.gps_lat = res.latitude;
						this.shopInfo.gps_lng = res.longitude;
						$("#loading").hide();
						this.$http.post("http://api-sch-saas.aiwx.org.cn/api/get_gps_address", {
								gps: res.latitude + "," + res.longitude,
							})
							.then((res) => {
								this.shopInfo.address = res.data.result.address;
								console.log(this.shopInfo)
							});

					},
				});
			},

			saveForm() {
				this.$http.post("/api/edit_one_shop",this.shopInfo).then(res=>{
					
					this.$$alert({msg:"保存成功",okcb:e=>{
						this.$router.push("/h5Me")
					}})
					
					this.$http.post("/api/set_shop_prices",{prices:this.priceList}).then(res=>{
						
						
						
					})
					
				})
			},

		}
	}
</script>

<style>
</style>
